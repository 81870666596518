import { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';


function Testimonialsabout() {
    const { t } = useTranslation('about');
    return (
        <>
            <section className="testimonials-one">
                <div className="testimonials-one__boxed">
                    <div className="container">
                        <Swiper className="swiper-container testimonials-one__thumb"
                            loop={false}
                            slidesPerView={3}
                            spaceBetween={0}
                            speed={1400}
                            watchSlidesProgress={true}
                            autoplay={{ delay: 5000 }} >
                            <div className="swiper-wrapper">
                                <SwiperSlide>
                                    <div className="swiper-slide" >
                                        <img src="assets/images/resources/support.png" alt="" style={{backgroundColor: "#fc8444"}} />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/excellence.png" alt="" style={{backgroundColor: "#fc8444"}}/>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <img src="assets/images/resources/verify.png" alt="" style={{backgroundColor: "#fc8444"}}/>
                                    </div>
                                </SwiperSlide>
                            </div>
                        </Swiper>
                        <Swiper className="swiper-container testimonials-one__carousel"
                            modules={[Mousewheel]}
                            loop={false}
                            speed={1400}
                            mousewheel={true}
                            slidesPerView={1}
                            autoplay={{ delay: 5000 }} >
                            <div className="swiper-wrapper">
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <p className="testimonials-one__text">{t('testimonials.slide1')}</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <p className="testimonials-one__text">{t('testimonials.slide2')}</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        <p className="testimonials-one__text"> {t('testimonials.slide1')} </p>
                                    </div>
                                </SwiperSlide>
                            </div>
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonialsabout