import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Ready() {
  const { t } = useTranslation('home'); // specify the namespace here

  const listItems = t('ready.list', { returnObjects: true });

  return (
    <>
      <section className="about-four">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="about-four__image clearfix wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0">
                <img src="assets/images/resources/about-4-1.webp" alt="" />
                <div className="about-four__image-text">
                  {t('ready.ecoFriendlyText')}
                  <br />
                  <Link to="/about">
                    {t('ready.learnMore')}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="about-four__content">
                <div className="section-title">
                  <span className="section-title__tagline">{t('ready.commitmentTagline')}</span>
                  <h2 className="section-title__title">
                    {t('ready.conserveWaterTitle')}
                  </h2>
                </div>
                <div className="about-four__text">
                  {t('ready.description')}
                </div>

                <ul className="list-unstyled about-four__list">
                  {listItems.map((item, index) => (
                    <li className="about-four__list-item" key={index}>
                      <i className="fa fa-check-circle"></i>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ready;
