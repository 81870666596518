import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';


function Gallery() {
  return (
    <>
         <div className="gallery-two">
            <Swiper className="swiper-container thm-swiper__slider"
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{delay:5000}}
                    breakpoints={{
                        "376": {
                            "spaceBetween": 30,
                            "slidesPerView": 2
                        },
                        "576": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "768": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "992": {
                            "spaceBetween": 30,
                            "slidesPerView": 4
                        },
                        "1200": {
                            "spaceBetween": 30,
                            "slidesPerView": 5
                        }
                    }}>
                    <div className="swiper-wrapper">
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <div className="gallery-one__item">
                                <img src="assets/images/gallery/1.jpg" alt="" />
                                <a className="img-popup" href="assets/images/gallery/1.jpg"><i className="crsine-plus"></i></a>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <div className="gallery-one__item">
                                <img src="assets/images/gallery/2.jpg" alt="" />
                                <a className="img-popup" href="assets/images/gallery/2.jpg"><i className="crsine-plus"></i></a>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <div className="gallery-one__item">
                                <img src="assets/images/gallery/3.jpg" alt="" />
                                <a className="img-popup" href="assets/images/gallery/3.jpg"><i className="crsine-plus"></i></a>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <div className="gallery-one__item">
                                <img src="assets/images/gallery/4.jpg" alt="" />
                                <a className="img-popup" href="assets/images/gallery/4.jpg"><i className="crsine-plus"></i></a>
                            </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <div className="gallery-one__item">
                                <img src="assets/images/gallery/5.jpg" alt="" />
                                <a className="img-popup" href="assets/images/gallery/5.jpg"><i className="crsine-plus"></i></a>
                            </div>
                        </div>
                        </SwiperSlide>
                    </div>
                </Swiper>
            </div>
    </>
  )
}

export default Gallery