import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function First() {
  const { t } = useTranslation('services');

  const services = [
    {
      id: 1,
      img: "assets/images/services/full-wash.webp",
      icon: "crsine-car-service-1",
      key: "fullServiceWash" 
    },
    {
      id: 2,
      img: "assets/images/services/exterior-wash.webp",
      icon: "crsine-car-service-2",
      key: "exteriorServiceWash"
    },
    {
      id: 3,
      img: "assets/images/services/interior-wash.webp",
      icon: "crsine-car-service-4",
      key: "interiorServiceWash"
    },
    {
      id: 4,
      img: "assets/images/services/interior-wash.webp",
      icon: "crsine-car-service-4",
      key: "PremiumFullWash"
    },
    {
      id: 5,
      img: "assets/images/services/interior-wash.webp",
      icon: "crsine-car-service-4",
      key: "PreTripCheck"
    },
    {
      id: 6,
      img: "assets/images/services/interior-wash.webp",
      icon: "crsine-car-service-4",
      key: "PreTechnicalInspectionCheck"
    },
    {
      id: 7,
      img: "assets/images/services/interior-wash.webp",
      icon: "crsine-car-service-4",
      key: "FluidOrOilLivelling"
    },
    {
      id: 8,
      img: "assets/images/services/interior-wash.webp",
      icon: "crsine-car-service-4",
      key: "ChangingWindScreenWipers"
    },
    {
      id: 9,
      img: "assets/images/services/interior-wash.webp",
      icon: "crsine-car-service-4",
      key: "CleaningHeadlights"
    },
    {
      id: 10,
      img: "assets/images/services/interior-wash.webp",
      icon: "crsine-car-service-4",
      key: "ReducingScratches"
    }
  ];

  return (
    <>
     <Helmet>
        <title>{t('servicesPageTitle')}</title>
        <meta name="description" content={t('servicesPageDescription')} />
        <link rel="canonical" href="https://washminute.com/services" />
      </Helmet>
  
    <section className="service-one">
      <div className="container">
        <div className="row">
          {services.map((item) => {
            const service = t(`services.${item.key}`, { returnObjects: true });
            const isComingSoon = item.id >= 4 && item.id <= 10;

            return (
              <div className="col-sm-12 col-md-6 col-lg-4" key={item.id}>
                <div className="service-card">
                  <div className="service-card__image" style={{ filter: isComingSoon ? 'blur(10px)' : 'none' }}>
                    <img src={item.img} alt="" />
                    {isComingSoon ? (
                      <div className="service-card__coming-soon">
                      
                      </div>
                    ) : (
                      <Link to={`/servicedetails/${item.id}`} state={{ service }}>
                        <i className="crsine-plus"></i>
                      </Link>
                    )}
                  </div>
                  <div className="service-card__content">
                    <div className="service-card__icon" style={{ filter: isComingSoon ? 'blur(5px)' : 'none' }}>
                      <i className={item.icon}></i>
                    </div>
                    <h3 className="service-card__title">
                      {isComingSoon ? (
                        service.name
                      ) : (
                        <Link to={`/servicedetails/${item.id}`} state={{ service }}>
                          {service.name}
                        </Link>
                      )}
                    </h3>
                    <p className="service-card__text">
                      {isComingSoon ? t('soon') : service.tag}
                    </p>
                    {!isComingSoon && (
                      <Link to={`/servicedetails/${item.id}`} state={{ service }} className="service-card__more">
                        <i className="crsine-right-arrow"></i>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="service-one__load text-center">
        </div>
      </div>
    </section>
    </>
  );
}

export default First;
