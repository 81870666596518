import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import BASE_URL from '../../../config';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

function First() {
  const [posts, setPosts] = useState([]);
  const { t, i18n } = useTranslation('blogs');
  const [itemsPerPage, setItemsPerPage] = useState(6); 
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
       const response = await axios.get(`${BASE_URL}/api/blogs`); 
       const sortedPosts = response.data.sort((a, b) => new Date(b.customUpdatedAt) - new Date(a.customUpdatedAt));
       setPosts(sortedPosts);
       console.log(sortedPosts)
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString(i18n.language, { month: 'short' });
    return `${day} ${month}`;
  };

  const totalPages = Math.ceil(posts.length / itemsPerPage);

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const paginatedData = posts.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);


  return (
    <>
      <Helmet>
        <title>{t('blogPageTitle')}</title>
        <meta name="description" content={t('blogPageDescription')} />
        <link rel="canonical" href="https://washminute.com/newsmain" />
      </Helmet>
      <section className="news-page">
        <div className="container">
          <div className="row">
            {paginatedData.map((item) => (
              <div className="col-sm-12 col-md-6 col-lg-4" key={item._id}>
                <div className="blog-card">
                  <div className="blog-card__image" style={{height: "226px"}}>
                    <img src={`${BASE_URL}/api/uploads/${item.mainImage}`} alt={item.imageDescription} />
                    <Link to={`/newsdetails/${item._id}`} ></Link>
                  </div>
                  <div className="blog-card__content">
                    <div className="blog-card__date">{formatDate(item.customUpdatedAt)}</div>
                    <ul className="list-unstyled blog-card__meta">
                      <li className="blog-card__meta-item">
                        <Link to="#">
                          <i className="far fa-user-circle"></i>
                          {t("by")} Admin
                        </Link>
                      </li>
                    </ul>
                    <h3 className="blog-card__title">
                      <Link to={`/newsdetails/${item._id}`} >
                        {item.title}
                      </Link>
                    </h3>
                    <p className="blog-card__text">
                    {/* <p>{item.sections && item.sections.length > 0 ? item.sections[0].content : 'No content available'}</p> */}
                    <p>{item.content1}</p>
                    </p>
                    <Link to={`/newsdetails/${item._id}`} className="blog-card__more">
                      {t("readMore")}
                      <i className="far fa-arrow-alt-circle-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="pagination-controls">
          <button onClick={handlePrev} disabled={currentPage === 0} className="btn-pagination">
            <FaChevronLeft />
          </button>
          <button onClick={handleNext} disabled={currentPage === totalPages - 1} className="btn-pagination">
            <FaChevronRight />
          </button>
        </div>

          <ul className="list-unstyled post-pagination d-flex justify-content-center align-items-center">
          </ul>
        </div>
      </section>
    </>
  );
}

export default First;
