import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


function PriceCard({ timeLapse, item, isLastItem, samePage, onBookNow }) {
  const { t } = useTranslation('pricing');
  const linkTarget = samePage ? "#booking-from-section" : "/onlinebooking";

  const handleClick = () => {
    const serviceName = item.serviceId?.name || 'Unknown Service';
    const servicePrice =  item.price || 0 ;
    onBookNow(serviceName,servicePrice);
  };

  return (
    <div className="col-md-12 col-lg-4">
      <div className="price-card">
        {/* {!item.available && <div className="price-card__tag">{t('comingSoon')}</div>} */}
        <p className="price-card__name"> {item.serviceId?.name || 'Unnamed Service'}</p>
        <p className="price-card__amount">
          <span className="price-card__amount-symbol">DH</span>
          {item.price || 0 }
          <span className="price-card__amount-fraction">.00</span>
        </p>
        <div className="price-card__duration">
          <i className="far fa-clock"></i>
          {isLastItem ? 30 : timeLapse} {t('minutes')}
        </div>
        {samePage ? (
          <a href={linkTarget} className="thm-btn" onClick={handleClick}>
            {t('bookNow')} <i className="far fa-arrow-alt-circle-right"></i>
          </a>
        ) : (
          <Link to={linkTarget} className="thm-btn">
            {t('bookNow')} <i className="far fa-arrow-alt-circle-right"></i>
          </Link>
        )}

      </div>
    </div>
  );
}

export default PriceCard;
