import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function Messageabout() {
  const { t } = useTranslation('about');

  return (
    <>
            <Helmet>
                <title>{t('metaTitle')}</title>
                <meta name="description" content={t('metaDescription')} />
                <link rel="canonical" href="https://washminute.com/about" />
            </Helmet>
      <section className="about-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img src="assets/images/resources/1.jpg" alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6">
              <img src="assets/images/resources/2.jpg" alt="" className="img-fluid" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="section-title">
                <span className="section-title__tagline">{t('messageabout.writeMessage')}</span>
                <h2 className="section-title__title">{t('messageabout.leadingCarwash')}</h2>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="about-one__content">
                <p className="about-one__highlighted">{t('messageabout.highlightedText')}</p>
                <p className="about-one__text">{t('messageabout.description')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Messageabout;
