import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function First() {
  const { t } = useTranslation('partners');
  const [itemsPerPage, setItemsPerPage] = useState(3); 
  const [currentPage, setCurrentPage] = useState(0);

  const data = [
    {
      id: 1,
      img: "assets/images/partners/aradei.webp",
      link: "https://www.aradeicapital.com/en/"
    },
    {
      id: 2,
      img: "assets/images/partners/ebf.webp",
      link: "https://emergingbusinessfactory.com/en/"
    },
    {
      id: 3,
      img: "assets/images/partners/feuver.webp",
      link: "https://feuvert.ma/",
    },
    {
      id: 4,
      img: "assets/images/partners/tamwilcom.webp",
      link: "https://www.tamwilcom.ma/fr/tamwilcom/qui-sommes-nous",
    },
    {
      id: 5,
      img: "assets/images/partners/near.webp",
      link: "https://neareast.org/",
    },
    {
      id: 6,
      img: "assets/images/partners/bmci.webp",
      link: "https://www.bmci.ma/",
    }
  ];

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const paginatedData = data.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  useEffect(() => {
    const updateItemsPerPage = () => {
      if (window.innerWidth < 768) {
        setItemsPerPage(1);
      } else {
        setItemsPerPage(3);
      }
    };

    updateItemsPerPage(); 
    window.addEventListener('resize', updateItemsPerPage);

    return () => {
      window.removeEventListener('resize', updateItemsPerPage);
    };
  }, []);

  return (
    <section className="news-page">
        <style>{`
      .blog-card__image {
        position: relative;
        overflow: hidden; /* Ensures no spillover */
        height: 170px; /* Fixed height to maintain uniformity */
      }
      .blog-card__image img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Ensures the image covers the area nicely */
        display: block; /* Removes bottom space/gap */
      }
      .blog-card__image a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    `}</style>
      <div className="container">
        <div className="row horizontal-pagination">
          {paginatedData.map((item) => (
            <div key={item.id} className="col-sm-12 col-md-6 col-lg-4">
              <div className="blog-card">
                <div className="blog-card__image">
                  <img src={item.img} alt="" style={{ maxHeight: "170px" }} />
                  <a href={item.link} target="_blank" rel="noopener noreferrer"></a>
                </div>
                <div className="blog-card__content">
                  <h3 className="blog-card__title">
                    {t(`leaderTitle${item.id}`)}
                  </h3>
                  <p className="blog-card__text">
                    {t(`leaderDescription${item.id}`)}
                  </p>
                  <a href={item.link} className="blog-card__more" target="_blank" rel="noopener noreferrer">
                    {t('findOutMore')}
                    <i className="far fa-arrow-alt-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination-controls">
          <button onClick={handlePrev} disabled={currentPage === 0} className="btn-pagination">
            <FaChevronLeft />
          </button>
          <button onClick={handleNext} disabled={currentPage === totalPages - 1} className="btn-pagination">
            <FaChevronRight />
          </button>
        </div>
      </div>
    </section>
  );
}

export default First;