import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import global_en from "./components/Translation/en/global.json";
import global_fr from "./components/Translation/fr/global.json";
import home_en from "./components/Translation/en/home.json";
import home_fr from "./components/Translation/fr/home.json";
import about_en from "./components/Translation/en/about.json";
import about_fr from "./components/Translation/fr/about.json";
import pricing_en from "./components/Translation/en/pricing.json";
import pricing_fr from "./components/Translation/fr/pricing.json";
import partner_en from "./components/Translation/en/partners.json";
import partner_fr from "./components/Translation/fr/partners.json";
import contact_en from "./components/Translation/en/contact.json";
import contact_fr from "./components/Translation/fr/contact.json";
import services_en from "./components/Translation/en/services.json";
import services_fr from "./components/Translation/fr/services.json";
import blogs_fr from "./components/Translation/fr/blogs.json";
import blogs_en from "./components/Translation/en/blogs.json";
import benefits_fr from "./components/Translation/fr/benefits.json";
import benefits_en from "./components/Translation/en/benefits.json";
import conditions_en from "./components/Translation/en/conditions.json";
import conditions_fr from "./components/Translation/fr/conditions.json";
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolation: {escapeValue: true},
  lng: "en",
  resources: {
    en: {
      global: global_en,
      home: home_en,
      about: about_en,
      pricing: pricing_en,
      partners: partner_en,
      contact: contact_en,
      services: services_en,
      blogs : blogs_en,
      conditions : conditions_en,
      benefits : benefits_en
    },
    fr: {
      global: global_fr,
      home: home_fr,
      about: about_fr,
      pricing: pricing_fr,
      partners: partner_fr,
      contact: contact_fr,
      services: services_fr,
      blogs : blogs_fr,
      conditions : conditions_fr,
      benefits : benefits_fr

    },
  },
  ns: ['global', 'home', 'about', 'pricing','partners','contact', 'services',"blogs","benefits" , "conditions"],
  defaultNS: 'global',
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
    <App />
    </I18nextProvider>
  
  </React.StrictMode>
);