import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { toast,ToastContainer } from "react-toastify";
import axios from 'axios';
import BASE_URL from '../../../config';
import { Helmet } from 'react-helmet';

function Workingcontact() {
  const { t } = useTranslation('contact');

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [massage, setMassage] = useState("");
  const [tel, setTel] = useState("");
  const [subject, setSubjet] = useState("");
  const [loading, setLoading] = useState(false);
  //const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post(`${BASE_URL}/api/send-email`, {
        name,
        email,
        message: massage,
        tel,
        subject
      });
     // setMessage("Message sent successfully!");
      setName('');
      setEmail('');
      setMassage('');
      setTel('');
      setSubjet('');
      toast.success(t('messageSent'));


    } catch (error) {
      console.error("There was an error sending the email!", error);
     // setMessage("Failed to send message.");
      toast.error(t('messageFailed'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
            <Helmet>
                <title>{t('metaTitle')}</title>
                <meta name="description" content={t('metaDescription')} />
                <link rel="canonical" href="https://washminute.com/contact" />
            </Helmet>
      <section className="contact-one">
        <div className="container">
          <div className="section-title">
            <span className="section-title__tagline">{t('writeMessage')}</span>
            <h2 className="section-title__title">
            {t('connectedHelp')} <br />
            {t('time')}
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="contact-one__content">
                <p className="contact-one__text">
                {t('alwaysReady')}
                </p>
                <p className="contact-one__address">
                {t('address')}
                <br />
                Marrakech (Carrefour Targa)
                </p>
                <ul className="contact-one__list list-unstyled">
                  <li>
                    <Link to="mailto:contact@washminute.com">
                      <i className="fa fa-envelope"></i>contact@washminute.com
                    </Link>
                  </li>
                  <li>
                    <Link to="tel:92-666-888-000">
                      <i className="fa fa-phone-square-alt"></i>+212-618509180
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <form className="contact-form-validated contact-one__form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      placeholder={t('yourName')}
                      name="name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      placeholder={t('emailAddress')}
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                    />
                  </div>
                  {/* <div className="col-md-6">
                    <input
                      type="text"
                      placeholder={t('phoneNumber')}
                      name="phone"
                      value={tel}
                      onChange={(e) => {
                        setTel(e.target.value);
                      }}
                      required
                    />
                  </div> */}
                  <div className="col-md-12">
                    <input
                      type="text"
                      placeholder={t('subject')}
                      name="subject"
                      value={subject}
                      onChange={(e) => {
                        setSubjet(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      name="message"
                      placeholder={t('writeComment')}
                      value={massage}
                      onChange={(e) => {
                        setMassage(e.target.value);
                      }}
                      required
                    ></textarea>
                  </div>
                  <div className="col-md-12">
                    <button type="submit" className="thm-btn" disabled={loading}>
                    {t('sendMessage')}
                      <i className="far fa-arrow-alt-circle-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            
            </div>
          </div>
        </div>
      </section>
      <ToastContainer/>
    </>
  );
}

export default Workingcontact;
