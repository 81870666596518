
import Header from '../Menhometwo/Headertwo'
import First from '../Pages/MenConditions/First'
import { useTranslation } from 'react-i18next';
const Conditions = () => {
    const { t } = useTranslation();


    return (
        <>
            <Header/>
            {/* <Bredcom title={t("header.home")} subtitle={t("header.contact")} newtitle={t("header.contact")} bg={BG}/> */}

            <First />
          
        </>
    )
}
export default Conditions