import { BrowserRouter as Router } from "react-router-dom"
import Routing from './routes';
import './assets/vendors/bootstrap/css/bootstrap.min.css'
import './assets/vendors/animate/animate.min.css'
import './assets/vendors/fontawesome/css/all.min.css'
import './assets/vendors/swiper/swiper.min.css'
import './assets/vendors/crsine-icons/style.css'
import './assets/css/crsine.css'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
export default function App() {
  return (
    <Router>
        <Routing />
        <ToastContainer
          position="top-right"
          style={{
            color: 'white',
          }}
        />
    </Router>
  );
}

