import React from 'react'

import Header from '../Menhometwo/Headertwo'

import Bredcom from '../Bredcom/Main'
import Easy from '../Menhome/Easy'
import Choose  from '../Pages/Menpricing/First'
import { useTranslation } from 'react-i18next'
import Clint from '../Menhome/Clint'


const Pricing = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="page-wrapper">
                <Header/>
                <Bredcom title={t("header.home")} bg={'/assets/images/backgrounds/pricingBanner.jpg'} subtitle={t("header.topQuality")} newtitle={t("header.pricing")} />
        <Clint/>

                <Choose />
                <Easy/>
            </div>
        </>
    )
}

export default Pricing
