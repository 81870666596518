import React, { useRef } from 'react'
import { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';



function Quality() {

    const activeRef = useRef(null);
    const { t } = useTranslation('benefits');


    return (
        <>


            <section className="testimonials-one testimonials-one__home-two">
                <div className="section-title text-center">
                    <span className="section-title__tagline">{t('section2')}</span>
                    <h2 className="section-title__title">{t('title2')}</h2>
                </div>
                <div className="testimonials-one__boxed">
                    <div className="container">
                        <Swiper className="swiper-container testimonials-one__carousel" modules={[Mousewheel]} loop={false}
                            speed={1400} mousewheel={true} slidesPerView={1} autoplay={{ delay: 5000 }}>
                            <div className="swiper-wrapper">
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        {/* <img src="assets/images/resources/clt1.jpg" alt="" /> */}

                                        <p className="testimonials-one__text">{t('tes1')}</p>
                                        <div className="testimonials-two__meta">
                                            <h3 className="testimonials-one__name">Abdelhafid</h3>
                                            <span className="testimonials-one__designation">{t('clt1')}</span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        {/* <img src="assets/images/resources/clt2.jpg" alt="" /> */}
                                        <p className="testimonials-one__text">{t('tes2')}</p>
                                        <div className="testimonials-two__meta">
                                            <span className="testimonials-one__designation">{t('clt')}</span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        {/* <img src="assets/images/resources/clt3.jpg" alt="" /> */}

                                        <p className="testimonials-one__text">{t('tes3')}</p>
                                        <div className="testimonials-two__meta">
                                            <span className="testimonials-one__designation">{t('clt3')}</span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="swiper-slide">
                                        {/* <img src="assets/images/resources/clt4.jpg" alt="" /> */}

                                        <p className="testimonials-one__text">{t('tes4')}</p>
                                        <div className="testimonials-two__meta">
                                            <h3 className="testimonials-one__name">Cyril</h3>
                                            <span className="testimonials-one__designation">{t('clt4')}</span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </div>
                        </Swiper>
                        <div className="swipe-indicator">
                            <span>{t('swipeForMore')}</span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Quality