import React from 'react'
import First from '../Mennewsdeatils/First'
import { useTranslation } from 'react-i18next'


const NewsDetails = () => {
  const { t } = useTranslation('blogs');


  return (
    <>
        <div className="page-wrapper">
            <First/>
        </div>
    </>
  )
}

export default NewsDetails