import React from 'react';
import { useLocation } from 'react-router-dom';
import First from '../Menservisedeatils/First';

const ServiceDetails = () => {
  const location = useLocation();
  const service = location.state?.service;

  return (
    <div className="page-wrapper">
      <First service={service} />
    </div>
  );
}

export default ServiceDetails;
