import BGImg3 from '../../assets/images/backgrounds/main-slider-2-2.webp';
import BGImg2 from '../../assets/images/backgrounds/main-slider-2-1.webp';
import { Link } from 'react-router-dom';
import React from 'react';
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';

function Slidertwo() {
  const { t } = useTranslation('home'); // specify the namespace here

  return (
    <>
      <section className="main-slider main-slider-two">
        <Swiper
          className="swiper-container thm-swiper__slider"
          modules={[Navigation, Autoplay]}
          slidesPerView={1}
          loop={true}
          navigation={{
            nextEl: "#main-slider__swiper-button-next",
            prevEl: "#main-slider__swiper-button-prev"
          }}
          autoplay={{ delay: 5000 }}
        >
          <div className="swiper-wrapper">
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="image-layer" style={{ backgroundImage: `url(${BGImg2})` }}>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <p>{t('sliderTwo.welcomeMessage')}</p>
                      <h2>{t('sliderTwo.slide1Title')}</h2>
                      <Link to="/onlinebooking" className="thm-btn">{t('sliderTwo.orderNow')} <i className="far fa-arrow-alt-circle-right"></i></Link>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="image-layer" style={{ backgroundImage: `url(${BGImg3})` }}>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <p>{t('sliderTwo.welcomeMessage')}</p>
                      <h2>{t('sliderTwo.slide2Title')}</h2>
                      <Link to="/onlinebooking" className="thm-btn">{t('sliderTwo.orderNow')} <i className="far fa-arrow-alt-circle-right"></i></Link>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </div>

          <div className="main-slider__nav-two">
            <div className="swiper-button-prev" id="main-slider__swiper-button-next"><i className="crsine-left-arrow"></i></div>
            <div className="swiper-button-next" id="main-slider__swiper-button-prev"><i className="crsine-right-arrow"></i></div>
          </div>
        </Swiper>
      </section>
    </>
  );
}

export default Slidertwo;