import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

function Headertwo() {
  const [t, i18n] = useTranslation("global");
  const [cartItems, setCartItems] = useState([
    {
      id: 1,

      name: t('header.en'),
  
      image: "../../assets/images/products/1 (1).png",
      tag: "en"
    },
    {
      id: 2,

      name: t('header.fr'),
    

      image: "../../assets/images/products/1 (2).png",
      tag: "fr"
    },
   
  ]);



  
  const location = useLocation();
  const path = location.pathname;
  const [menu, setmenu] = useState({});
  const [search, setsearch] = useState(false);
  const [cart, setcart] = useState(false);
  const [mobile, setmobile] = useState(false);
  const [homeDrop, sethomeDrop] = useState(false);

  const [servicesDrop, setservicesDrop] = useState(false);
  
  const activeMenu = () => {
    if (path === "/") {

      setmenu({ home: true });
    } else if (path === "/about") {
      setmenu({ about: true });
    }else if (path === "/onlinebooking") {
      setmenu({ booking: true });
    }
    else if (path === "/contact") {
      setmenu({ contact: true });
    }
    // else if (path === "/pricing") {
    //   setmenu({ pricing: true });
    // }
     else if (path === "/services" || path === "/servicedetails") {
      setmenu({ services: true });
    } else if (
      path === "/onlinebooking" ||
      // path === "/pricnig" ||
      path === "/contact" ||
      path === "/faqs" ||
      path === "/gallery" ||
      path === "/help" ||
      path === "/error"
    ) {
      setmenu({ pages: true });
    } else if (path === "/shops" || path === "/shopdetails") {
      setmenu({ shop: true });
    } else if (path === "/newsmain" || path === "/newsdetails/:id") {
      setmenu({ blogs: true });
    }else if (path === "/partners") {
      setmenu({ partners: true });
    }
     else {
      setmenu({ home: true });
    }
  };

  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    activeMenu();
  }, [path]);
  const isSticky = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 141 ? setSticky(true) : setSticky(false);
  };
   
  const handleChangeLanguage = (lang) =>{
      i18n.changeLanguage(lang);
      setcart(false)
  }
  return (
    <>
     <style>
        {`
           @media (max-width: 768px) {
    .main-menu__logo {
      display: none;
    }
            .main-menu__right {
              display: flex;
              flex-direction: row-reverse;
              justify-content: space-between;
              width: 100%;
            }
            .main-menu__cart {
              margin-right: auto;  // Pushes the globe icon to the left
            }
          }
        `}
      </style>
      <header className="main-header main-header-two">
        <nav className="main-menu main-menu__two">
          <div className="container-fluid ">
            <div className="main-menu__inner">
              <div className="main-menu__logo">
                <Link to="/">
                  <img
                   className="logo-image"
                    src="assets/images/logo-dark.png"
                    width={137}
                    alt="washMinute  - Car washing  services"
                  />
                </Link>
              </div>

              <ul className="main-menu__list">
                <li className="dropdown">
                  <Link to="/">{t("header.home")}</Link>
                </li>
                <li>
                  <Link to="/about">{t("header.about")}</Link>
                </li>
                <li className="dropdown">
                  <Link to="/services">{t("header.services")}</Link>
                  <ul>
                    <li>
                      <Link to="/services">{t("header.allServices")}</Link>
                    </li>
                  
                  </ul>
                </li>
                {/* <li>
                  <Link to="/pricing">{t("header.pricing")}</Link>
                </li> */}
                <li>
                 <Link to="/onlinebooking">{t("header.onlineBooking")}</Link>
                 </li>
                 <li className="dropdown">
                  <Link to="/partners">{t("header.partners")}</Link>
                </li>
                <li>
                  <Link to="/newsmain">{t("header.blogs")}
                  </Link>
                </li>
             
                <li>
                  <Link to="/contact">{t("header.contact")}</Link>
                </li>
              
              </ul>
              <div className="main-menu__right">
                <Link
                  to="#"
                  onClick={() => setmobile(true)}
                  className="mobile-nav__toggler"
                >
                  <i className="fa fa-bars"></i>
                </Link>
                {/* <Link
                  to="#"
                  onClick={() => setsearch(true)}
                  className="main-menu__search search-toggler crsine-magnifying-glass"
                ></Link> */}
                <Link
                  to="#"
                  onClick={() => setcart(true)}

                  className="main-menu__cart fa fa-globe mini-cart__toggler"
                ></Link>
                <Link to="/onlinebooking" className="thm-btn">
                {t("header.appointment")}
                  <i className="far fa-arrow-alt-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div
        className={`stricky-header stricked-menu main-menu main-menu__two ${
          sticky &&
          "stricky-header stricked-menu main-menu main-menu__two stricky-fixed"
        }`}
      >
        <div className="main-menu__inner">
          <div className="main-menu__logo">
            <Link to="/">
              <img
                src="assets/images/logo-dark.png"
                width={137}
                alt="WashMinute - Eco car washing"
              />
            </Link>
          </div>
          <ul className="main-menu__list">
            <li className={`dropdown ${menu.home && "current"}`}>
              <Link to="/">{t("header.home")}</Link>

        

            </li>
            <li className={`dropdown ${menu.about && "current"}`}>
              <Link to="/about">{t("header.about")}</Link>
            </li>
            <li className={`dropdown ${menu.services && "current"}`}>
              <Link to="/services">{t("header.services")}</Link>
              <ul>
                <li>
                  <Link to="/services">{t("header.allServices")}</Link>
                </li>
            
              </ul>
            </li>

            {/* <li className={`dropdown ${menu.pricing && "current"}`}>
              <Link to="/pricing">{t("header.pricing")}</Link>
            </li> */}
            <li className={`dropdown ${menu.booking && "current"}`}>
              <Link to="/onlinebooking">{t("header.onlineBooking")}</Link>
            </li>
            <li className={`dropdown ${menu.partners && "current"}`}>
              <Link to="/partners">{t("header.partners")}</Link>
            </li>
            <li className={`dropdown ${menu.blogs && "current"}`}> 
              <Link to="/newsmain"> {t("header.blogs")}
              </Link>
            </li>
            <li className={`dropdown ${menu.contact && "current"}`}>
              <Link to="/contact">{t("header.contact")}</Link>
            </li>
            
            
          </ul>
          <div className="main-menu__right">
            <Link
              to="#"
              onClick={() => setmobile(true)}
              className="mobile-nav__toggler"
            >
              <i className="fa fa-bars"></i>
            </Link>
            {/* <Link
              to="#"
              onClick={() => setsearch(true)}
              className="main-menu__search search-toggler crsine-magnifying-glass"
            ></Link> */}
            <Link
              to="#"
              onClick={() => setcart(true)}
               className="main-menu__cart fa fa-globe mini-cart__toggler"
            ></Link>
            <Link to="/onlinebooking" className="thm-btn">
            {t("header.appointment")}
              <i className="far fa-arrow-alt-circle-right"></i>
            </Link>
          </div>
        </div>
      </div>

      <div className={`search-popup ${search && "active"}`}>
        <div
          className="search-popup__overlay search-toggler"
          onClick={() => setsearch(false)}
        ></div>
        <div className="search-popup__content">
          <form action="#">
            <label htmlFor="search" className="sr-only">
            {t("header.searchHere")}
            </label>
            <input type="text" id="search" placeholder={t("header.searchHere")} />
            <button
              type="submit"
              aria-label="search submit"
              className="thm-btn"
            >
              <i className="crsine-magnifying-glass"></i>
            </button>
          </form>
        </div>
      </div>

      <div className={`mini-cart ${cart ? "expanded" : ""}`}>
        <div
          className="mini-cart__overlay mini-cart__toggler"
          onClick={() => setcart(false)}
        ></div>
        <div className="mini-cart__content">
          <div className="mini-cart__top">

            <h3 className="mini-cart__title">{t("header.languages")}</h3>

            <span className="mini-cart__close mini-cart__toggler">
              <i className="organik-icon-close"></i>
            </span>
          </div>
          {cartItems.map((item) => (

            <div className="mini-cart__item mini-cart__item-content" key={item.id}>
              <img src={item.image} alt={item.name} style={{height: "30px" }}/>&nbsp; &nbsp;
                  <h3>
                    <button className="thm-btn " onClick={() => handleChangeLanguage(item.tag)}>{item.name}</button>
                  </h3>
            </div>
          ))}
        </div>
      </div>

      <div className={`mobile-nav__wrapper ${mobile ? "expanded" : ""}`}>
        <div
          className="mobile-nav__overlay mobile-nav__toggler"
          onClick={() => setmobile(false)}
        ></div>
        <div className="mobile-nav__content">
          <span
            className="mobile-nav__close mobile-nav__toggler"
            onClick={() => setmobile(false)}
          >
            <i className="fa fa-times"></i>
          </span>

          <div className="logo-box">
            <Link to="/" aria-label="logo image" onClick={() => setmobile(false)}>
              <img src="assets/images/logo-light.png" width="155" alt="" />
            </Link>
          </div>
          <div className="mobile-nav__container">
            <ul className="main-menu__list">
              <li className="dropdown current   ">
                <Link to="/" className={homeDrop ? "expanded" : ""}onClick={() => setmobile(false)}>
                {t("header.home")}

                </Link>
              </li>
              <li>
                <Link to="/about" onClick={() => setmobile(false)}>
                {t("header.about")}
                </Link>
              </li>
              <li className="dropdown">
                <Link to="#" className={servicesDrop ? "expanded" : ""}>
                {t("header.services")}
                  <button
                    aria-label="dropdown toggler"
                    className={servicesDrop ? "expanded" : ""}
                    onClick={() => setservicesDrop(servicesDrop ? false : true)}
                  >
                    <i className="fa fa-angle-down"></i>
                  </button>
                </Link>
                {servicesDrop && (
                  <ul style={{ display: "block" }}>
                    <li>
                      <Link to="/services" onClick={() => setmobile(false)}>
                      {t("header.allServices")}
                      </Link>
                    </li>
                  </ul>
                )}
              </li>

              {/* <li>
                <Link to="/pricing" onClick={() => setmobile(false)}>
                {t("header.pricing")}
                </Link>
              </li> */}
              <li>
                <Link to="/onlinebooking" onClick={() => setmobile(false)}>
                {t("header.onlineBooking")}
                </Link>
              </li>
              <li>
                <Link to="/partners" onClick={() => setmobile(false)}>
                {t("header.partners")}
                </Link>
              </li>
              <li>
                <Link to="/newsmain" onClick={() => setmobile(false)}>
                {t("header.blogs")}
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={() => setmobile(false)}>
                {t("header.contact")}
                </Link>
              </li>
            </ul>
          </div>

          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="fa fa-envelope"></i>
              <Link to="mailto:contact@washminute.com">
              contact@washminute.com
              </Link>
            </li>
            <li>
              <i className="fa fa-phone-alt"></i>
              <Link to="tel:666-888-0000">+212-618509180</Link>
            </li>
          </ul>
          <div className="mobile-nav__top">
                <div className="mobile-nav__social">
                  <a 
                    href="https://www.linkedin.com/company/washminute/posts/?feedView=all&viewAsMember=true" 
                    className="fab fa-linkedin" 
                    target="_blank" 
                    rel="noopener noreferrer"
                  ></a>
                </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Headertwo;