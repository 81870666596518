import React from 'react'

function Map() {
  return (
    <>
        <div className="google-map__contact-page">
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3395.458113967556!2d-8.049849924520197!3d31.676041974143097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdafeb8a1b663eab%3A0x5906e3c0e59d8a4e!2sEmerging%20Business%20Factory%20-%20EBF!5e0!3m2!1sen!2sma!4v1723712280936!5m2!1sen!2sma" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3395.458113967556!2d-8.049849924520197!3d31.676041974143097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdafeb8a1b663eab%3A0x5906e3c0e59d8a4e!2sEmerging%20Business%20Factory%20-%20EBF!5e0!3m2!1sen!2sma!4v1723712280936!5m2!1sen!2sma" className="map__contact-page" allowFullScreen></iframe>
            {/* <iframe title="template google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd" className="map__contact-page" allowFullScreen></iframe> */}
        </div>
    </>
  )
}

export default Map