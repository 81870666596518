import React from 'react'
import { useTranslation } from 'react-i18next';
import aboutImage from '../../assets/images/resources/about-5.webp';

function Know() {
const { t } = useTranslation('home');
  return (
    <>
        <section className="about-five">
            <img src="assets/images/shapes/about-5-shape-1.png" className="image-layer" alt="" />
            <div className="container">
                    <div className="row flex-xl-row-reverse">
                        <div className="col-xl-6">
                            <div className="about-five__images" style={{ width: '100%', height: '410px', overflow: 'hidden' }}>
                                <img src={aboutImage} alt="" className="wow fadeInUp img-fluid" data-wow-duration="1500ms" data-wow-delay="100ms" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <div className="about-five__content">
                                <div className="section-title">
                                    <span className="section-title__tagline">{t("know.sectionTagline")}</span>
                                    <h2 className="section-title__title">{t("know.sectionTitle")} </h2>
                                </div>
                                    {/* <div className="about-five__summery">
                                        {t("know.sectionSummary")}
                                    </div> */}
                                <div className="about-five__text">
                                {t("know.sectionText")}
                                </div>
                            </div>
                           
                        </div>
                    </div>
            </div>
        </section>
    </>
  )
}

export default Know