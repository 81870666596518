import React from 'react';
import { useTranslation } from 'react-i18next';

// import 'swiper/css';  // Ensure you import Swiper styles
function Clint() {
  const { t } = useTranslation();
    const containerStyle = {
      overflow: 'hidden',
      position: 'relative',
      backgroundColor: '#fc8444',
      color: '#fff',
      padding: '20px 0',
      display: 'flex',
      alignItems: 'center',  // Centers the text vertically
      height: '40px',  // Fixed height for better control
    };
  
    const textStyle = {
      position: 'absolute',
      whiteSpace: 'nowrap',
      fontSize: '18px',
      padding: '0 50px',  // Added padding for spacing between messages
      willChange: 'transform',
      animation: 'scrollText 30s linear infinite',
    };
  
    return (
      <>
        <hr className="section-separator" />
        <div style={containerStyle}>
          <div style={textStyle}>
            {t('freeBookingFirstMonth')}
          </div>
        </div>
        <style>{`
          @keyframes scrollText {
            from {
              transform: translateX(100%);
            }
            to {
              transform: translateX(-100%);
            }
          }
          /* Additional styles for text shadow and outline */
          .scroll-text {
            text-shadow: 1px 1px 2px black;
            color: white;
            text-decoration: none;  /* Remove underline from links if any */
          }
        `}</style>
      </>
    );
  }
  
  export default Clint;