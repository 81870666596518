import axios from 'axios';
import BASE_URL from '../../config';



// Function to fetch all stations
export const fetchStations = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/stations/all`);
        return response.data; 
    } catch (error) {
        console.error('Failed to fetch stations:', error);
        return [];
    }
};  