import BGFBg1 from '../../assets/images/backgrounds/footer-bg-1.webp'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
export const Footer = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await axios.post('https://washminutebackend.azurewebsites.net/api/newsletter/add', { email });
            setEmail('');
            toast.success(t('Email saved succefully '));
        } catch (error) {
            console.error('Failed to subscribe to newsletter:', error);
            toast.error(t('footer.newsletter.errorMessage'));
        } finally {
            setLoading(false);
        }
    };
  return (
    <>
        <footer className="main-footer">
            <div className="image-layer" style={{ backgroundImage: `url(${BGFBg1})` }}></div>
            
            <div className="upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="footer-widget footer-widget__about">
                                <Link to="/" className="footer-widget__logo">
                                    <img src="assets/images/logo-light.png" width="137" alt="" />
                                </Link>
                                <p className="footer-widget__text">
                                {t('footer.description')}
                                </p>
                                <div className="footer-widget__social">
                                    <a href="https://www.linkedin.com/company/washminute/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-linkedin"></i>
                                    </a>
                                    {/* <Link to="#"><i className="fab fa-facebook-square"></i></Link>
                                    <Link to="#"><i className="fab fa-instagram"></i></Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="footer-widget footer-widget__links">
                                <h3 className="footer-widget__title">{t('footer.links.link')}</h3>
                                <div className="footer-widget__links-wrap">
                                    <ul className="list-unstyled">
                                        <li><Link to="/services">
                                        {t('footer.links.ourServices')}
                                            </Link></li>
                                        {/* <li><Link to="/about">
                                        {t('footer.links.bestCleaning')}
                                            </Link></li>
                                        <li><Link to="/about">
                                        {t('footer.links.qualityPolish')}
                                            </Link></li> */}
                                        <li><Link to="/contact">
                                        {t('footer.links.contact')}
                                            </Link></li>
                                        {/* <li><Link to="/faqs">
                                        {t('footer.links.help')} 
                                         </Link></li> */}
                                    </ul>
                                    <ul className="list-unstyled">
                                        {/* <li><Link to="/shops">
                                                New Products
                                            </Link></li> */}
                                        <li><Link to="/about">
                                        {t('footer.links.aboutStore')}
                                            </Link></li>
                                        {/* <li><Link to="/contact">
                                        {t('footer.links.support')}

                                            </Link></li> */}
                                        {/* <li><Link to="faqs.html">
                                                FAQs
                                            </Link></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="footer-widget footer-widget__contact">
                                <h3 className="footer-widget__title">{t('footer.links.contact')}</h3>
                                {/* <p className="footer-widget__text">
                                345 AL MASSAR ROUTE DE SAFI , Marrakech (Carrefour Targa)
                                </p> */}
                                <ul className="footer-widget__contact-list list-unstyled">
                                    <li>
                                        <Link to="mailto:contact@washminute.com"><i className="fa fa-envelope"></i>contact@washminute.com</Link>
                                    </li>
                                    <li>
                                        <Link to="tel:+212-618509180"><i className="fa fa-phone-square-alt"></i>
                                        +212-618509180
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="footer-widget footer-widget__mailchimp">
                                <h3 className="footer-widget__title">{t('footer.newsletter.title')}</h3>
                                <p className="footer-widget__text">{t('footer.newsletter.description')}</p>
                                <form className="footer-widget__mailchimp-form" onSubmit={handleSubmit}>
                                    <input
                                        type="email" // Ensure input type is email for proper validation
                                        placeholder={t('footer.newsletter.placeholder')}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required // Make sure the email field is required
                                    />
                                    <button type="submit" className="thm-btn" disabled={loading}>
                                        {t('footer.newsletter.buttonText')}
                                        <i className="far fa-arrow-alt-circle-right"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-footer">
                <div className="container" >
                    <div className="bottom-footer__inner">
                        <p className="bottom-footer__text" style={{ fontSize: '12px' }}>{t('footer.copyright')}</p>
                        <p className="bottom-footer__text" style={{ fontSize: '12px' }}>
                            <Link to="/conditions">{t('footer.terms')}</Link>
                            <Link to="/conditions">{t('footer.privacy')}</Link>
                        </p>
                    </div>
                </div>
            </div>

        </footer>
    </>
  )
}


export default Footer 