import React from "react";

function Team() {
  const team = [
    {
      id: 1,
      img: "assets/images/team/team-1-1.jpg",
      name: "Jessica Brown",
      subname: "Worker",
    },
    {
      id: 2,
      img: "assets/images/team/team-1-1.jpg",
      name: "Jordan Rodriquez",
      subname: "Worker",
    },
    {
      id: 3,
      img: "assets/images/team/team-1-1.jpg",
      name: "Leila Manning",
      subname: "Worker",
    },
    {
      id: 4,
      img: "assets/images/team/team-1-1.jpg",
      name: "Martin McLaughlin",
      subname: "Worker",
    },
  ];
  return (
    <>
      <section className="team-home-two">
        <div className="container">
          <div className="section-title text-center">
            {/* <span className="section-title__tagline">Meet Our Team</span>
            <h2 className="section-title__title">
              Meet Our Professional <br />
              Skilled Staff
            </h2> */}
          </div>
          <div className="row team-card__row">
            {/* {team.map((item) => (
              <div className="col-lg-6 col-xl-3">
                <div className="team-card">
                  <div className="team-card__image">
                    <img src={item.img} alt="" />
                  </div>
                  <div className="team-card__content">
                    <div className="team-card__social">
                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                      <Link to="#">
                        <i className="fab fa-facebook-square"></i>
                      </Link>
                      <Link to="#">
                        <i className="fab fa-pinterest-p"></i>
                      </Link>
                      <Link to="#">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </div>
                    <h3>{item.name}</h3>
                    <p>{item.subname}</p>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Team;
