import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function Easy() {
    const { t } = useTranslation();
    return (
        <>
           <section className="call-to-action">
                <div className="container">
                    <h3 className="call-to-action__title">{t('callToAction.title')}</h3>

                    <div className="call-to-action__btn-wrap">
                        <Link to="/onlinebooking" className="thm-btn">
                        {t('callToAction.buttonText')}
                            <i className="far fa-arrow-alt-circle-right"></i>
                        </Link >
                    </div>
                </div>
            </section>
        </>
    )
}

export default Easy