
import React from 'react'
import Headertwo from '../Menhometwo/Headertwo'
import Slidertwo from '../Menhometwo/Slidertwo'
import Know from '../Menhometwo/Know'
import Offers from '../Menhometwo/Offers'
import Ready from '../Menhometwo/Ready'
import Quality from '../Menhometwo/Quality'
import Find from '../Menhometwo/Find'
import Benefits from '../Menhome/Benefits'
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Home2 = () => {
    const { t } = useTranslation('home');

    return (
        <>
            <Headertwo/>
            <Slidertwo/>
            <Ready/>
            <Know/>
            <Find/>
            <Helmet>
                <title>{t('metaTitle')}</title>
                <meta name="description" content={t('metaDescription')} />
                <link rel="canonical" href="https://washminute.com/" />
            </Helmet>
            <Benefits/>
            <Offers/>
            <Quality/> 
           
        </>
    )
}

export default Home2