import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from 'react-i18next';

function First() {
  const { t } = useTranslation('conditions');

  return (
    <>
      <section className="terms-conditions">
        <div className="container">
          <h1>{t('title')}</h1>
          <h2>{t('acceptance_of_terms_title')}</h2>
          <p>{t('acceptance_of_terms_content')}</p>
          <h2>{t('contact_information_title')}</h2>
          <p>{t('contact_information_content')}</p>
          <h2>{t('arrival_and_waiting_title')}</h2>
          <p>{t('arrival_and_waiting_content')}</p>
          <h2>{t('payment_terms_title')}</h2>
          <p>{t('payment_terms_content')}</p>
          <h2>{t('cancellations_and_rescheduling_title')}</h2>
          <p>{t('cancellations_and_rescheduling_content')}</p>
          <h2>{t('service_conditions_title')}</h2>
          <p>{t('service_conditions_content')}</p>
          <h2>{t('liability_title')}</h2>
          <p>{t('liability_content')}</p>
          <h2>{t('privacy_and_data_use_title')}</h2>
          <p>{t('privacy_and_data_use_content')}</p>
          <h2>{t('modification_of_terms_title')}</h2>
          <p>{t('modification_of_terms_content')}</p>
          <h2>{t('governing_law_title')}</h2>
          <p>{t('governing_law_content')}</p>
        </div>
      </section>
      <ToastContainer/>
    </>
  );
}

export default First;
